import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';
import { useSession } from '@/components/context/SessionContext';
import { useReducer } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '@/lib/api';
import toast from 'react-hot-toast';

type TAccountReducerState = {
  username: string;
};

type TAccountReducerAction = { type: 'set_username'; username: string };

const useAccountReducer = (defaults: TAccountReducerState) => {
  return useReducer((state: TAccountReducerState, action: TAccountReducerAction) => {
    switch (action.type) {
      case 'set_username':
        return { ...state, username: action.username };
      default:
        return state;
    }
  }, defaults);
};

export const Route = createFileRoute('/_app/account/edit')({
  errorComponent: () => (
    <div className='flex flex-col'>
      You must be logged in to view this page
      <Link to='/login'>
        <button className='btn btn-primary'>Login</button>
      </Link>
    </div>
  ),

  component: () => {
    const { user } = useSession();

    if (!user) {
      throw new Error('No user found');
    }

    const [accountData, dispatch] = useAccountReducer(user);

    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const mutation = useMutation({
      mutationFn: async (data: TAccountReducerState) => {
        console.log('mutation', data);

        const res = await toast.promise(api.put('account', { json: data }), {
          loading: 'Saving...',
          success: 'Saved',
          error: 'Error'
        });
      },
      onSettled: async () => {
        navigate({ to: '/account' });
        queryClient.invalidateQueries({
          queryKey: ['session']
        });
      }
    });

    return (
      <div className={'mt-8 flex flex-col items-center'}>
        <div className={'card w-1/3 bg-base-200 shadow-lg'}>
          <div className={'card-body flex flex-col items-center'}>
            <div className={'mask mask-circle h-20 w-20'}>
              <div className={'h-full w-full bg-gray-400'}></div>
            </div>
            <div className={'flex w-full flex-col items-start'}>
              <input
                value={accountData.username}
                onChange={(e) => {
                  dispatch({ type: 'set_username', username: e.target.value });
                }}
                className='text-2xl font-bold'
              />
            </div>
            -- todo
            <div className='flex w-full flex-col'>
              <h3>Linked Accounts:</h3>
              <ul>
                <li>Google</li>
                <li>Facebook</li>
              </ul>
            </div>
            <div className='card-actions'>
              <button
                onClick={async () => {
                  await mutation.mutateAsync(accountData);
                }}
                className='btn btn-primary'
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
});
