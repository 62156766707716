import api from '@/lib/api';
import { paths } from '@repo/apitypes';

export function getRecipeMetadata(recipeSlug: string) {
  return {
    queryKey: ['recipes', recipeSlug, 'meta'],
    queryFn: async () => {
      return (await api
        .get(`recipes/${recipeSlug}/meta`)
        .json()) as paths['/recipes/{slug}/meta']['get']['responses']['2XX']['content']['application/json'];
    }
  };
}
