import { createFileRoute } from '@tanstack/react-router';
import RecipeCard from './-RecipeCard';

export const Route = createFileRoute('/_app/recipes/_list/explore')({
  component: () => (
    <div className='flex flex-col'>
      {/* Hello /_app/recipes/_list/explore! */}
      {/* <div>Shared</div> */}

      <div><h3>Friends Recipes</h3>
        <div className='flex flex-row gap-4'>

          {/* <div><figure><img/></figure></div> */}
          <RecipeCard 
          recipe={{
            shortSlug: 'test',
            name: 'Test Recipe',
            imageUrl: 'https://via.placeholder.com/241x160',
            accentColour: 'red',
            notes: 'This is a test recipe'
          }}/>

<RecipeCard 
          recipe={{
            shortSlug: 'test',
            name: 'Test Recipe',
            imageUrl: 'https://via.placeholder.com/240x160',
            accentColour: 'red',
            notes: 'This is a test recipe'
          }}/>
        </div>
      </div>
    </div>
  )
});
