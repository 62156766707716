'use client';

import api from '@/lib/api';
import { BaseIngredient, Category, IngredientVariant } from '@repo/database';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { ItemsSection } from './-ItemsSection';

export const Route = createFileRoute('/_app/account/settings/_layout/drinks')({
  component: DrinksPage
});

export default function DrinksPage() {
  // get categories
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedIngredient, setSelectedIngredient] = useState<string | null>(null);
  const [selectedVariant, setSelectedVariant] = useState<string | null>(null);

  const [editingType, setEditingType] = useState<'category' | 'ingredient' | 'variant' | null>(
    null
  );
  const [editingId, setEditingId] = useState<string | null>(null);

  useEffect(() => {
    setSelectedIngredient(null);
  }, [selectedCategory]);

  useEffect(() => {
    setSelectedVariant(null);
  }, [selectedIngredient]);

  const { data: categories, status: categoriesStatus } = useQuery({
    queryKey: ['categories', 'all'],
    queryFn: async () => {
      const data: Category[] = await api.get('categories/all').json();
      // setSelectedCategory(selectedCategory ?? data[0]?.id ?? null);
      return data;
    }
  });

  // get ingredients
  const { data: ingredients, status: ingredientsStatus } = useQuery({
    queryKey: ['ingredients', 'all', { categoryId: selectedCategory }],
    queryFn: async () => {
      return (await api
        .get(`ingredients/all`, { searchParams: { categoryId: selectedCategory ?? '' } })
        .json()) as BaseIngredient[];
    }
  });

  const { data: variants, status: variantsStatus } = useQuery({
    queryKey: ['ingredients', selectedIngredient, 'variants', 'all'],
    queryFn: async () => {
      return (await api
        .get(`ingredients/${selectedIngredient}/variants/all`)
        .json()) as IngredientVariant[];
    }
  });

  //todo render ingredients etc

  return (
    <div className='flex flex-col items-center'>
      <h1 className='my-6 text-2xl'>Manage ingredients</h1>
      <div className='h-80/ my-4 flex h-96 w-full flex-col justify-around md:flex-row'>
        <ItemsSection
          title='Categories'
          selectedItem={selectedCategory}
          setSelectedItem={setSelectedCategory}
          items={categories ?? []}
          status={categoriesStatus}
          edit={(item) => {
            console.log('edit');
            setEditingType('category');
            // setSelectedCategory(item);
            setEditingId(item);
          }}
        />
        <ItemsSection
          title='Ingredients'
          selectedItem={selectedIngredient}
          setSelectedItem={setSelectedIngredient}
          items={ingredients ?? []}
          status={selectedCategory == null ? 'idle' : ingredientsStatus}
          edit={() => {
            console.log('edit');
          }}
        />
        <ItemsSection
          title='Variants'
          selectedItem={selectedVariant}
          setSelectedItem={setSelectedVariant}
          items={variants ?? []}
          status={selectedIngredient == null ? 'idle' : variantsStatus}
          edit={() => {
            console.log('edit');
          }}
        />
      </div>
      <div>
        <EditComponent itemId={editingId ?? undefined} editingType={editingType} />
      </div>
    </div>
  );
}

function EditComponent({ itemId, editingType }: { itemId?: string; editingType: string | null }) {
  return (
    <div>
      {!editingType && 'Click pen icon to edit an item'}
      {!!editingType && <>Editing: {editingType}</>}
      {itemId}
    </div>
  );
}

function EditCategoryComponent({ itemId }: { itemId: string }) {
  const { data: categories, status: categoriesStatus } = useSuspenseQuery({
    queryKey: ['categories', 'id', itemId],
    queryFn: async () => {
      const data: Category[] = await api.get(`categories/${itemId}/info`).json();
      // setSelectedCategory(selectedCategory ?? data[0]?.id ?? null);
      return data;
    }
  });

  return <div>{JSON.stringify(categories)}</div>;
}
