// import Link from 'next/link';

import { createFileRoute, Link } from '@tanstack/react-router';
import { useSession } from '@/components/context/SessionContext';

export const Route = createFileRoute('/_app/account/')({ component: AccountPage });
export default function AccountPage() {
  // return {
  //   redirect: {
  //     destination: '/auth/login',
  //     permanent: false,
  //   },
  // };
  const { user } = useSession();
  return (
    <div className={'mt-8 flex flex-col items-center'}>
      <div className={'card w-1/3 bg-base-200 shadow-lg'}>
        <div className={'card-body flex flex-col items-center'}>
          <div className={'mask mask-circle h-20 w-20'}>
            <div className={'h-full w-full bg-gray-400'}></div>
          </div>
          <div className={'flex w-full flex-col items-start'}>
            <span className='text-2xl font-bold'>{user?.username}</span>
            <span className='text-xl font-medium'>{user?.email}</span>
          </div>

          <div className='flex w-full flex-col'>
            <h3>Linked Accounts:</h3>

            <ul>
              {user?.linkedAccounts.map((account) => (
                <li key='account'>
                  {account.replace(
                    /\w\S*/g,
                    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className='card-actions'>
            <Link to='/account/edit'>
              <button className='btn btn-primary'>Edit</button>
            </Link>
          </div>
        </div>
      </div>

      <div>
        <h3>Friends</h3>
        <div><input type='text'></input></div>
      </div>
    </div>
  );
}
