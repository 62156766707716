import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { getRecipeMetadata } from '@/lib/queries/GetRecipeMeta';

import { useQueryClient } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { Edit, Trash2 } from 'lucide-react';
import { MdShare } from 'react-icons/md';
import { RxDotsVertical } from 'react-icons/rx';

type TRecipe = {
  name: string;
  shortSlug: string;
  imageUrl: string;
  accentColour: string;
  notes: string;
}

export default function RecipeCard({
  recipe,
  openShareModal,
  triggerDelete
}: {
  recipe: TRecipe;
  openShareModal?: ()=> void;
  triggerDelete?: () => void;
}) {
  const queryClient = useQueryClient();
  const { queryFn, queryKey } = getRecipeMetadata(recipe.shortSlug);
  return (
    <>
      <Link to={`/recipes/$slug`} params={{ slug: recipe.shortSlug }}>
        <div
          className={`card relative h-96 w-72 cursor-pointer bg-base-300 shadow-lg transition-all ease-in-out hover:z-10 hover:scale-[102%] `}
          onMouseEnter={(e) => {
            e.preventDefault();
            queryClient.prefetchQuery({ queryFn, queryKey, staleTime: 10000 });
          }}
        >
          <figure className={`relative h-20 w-full overflow-hidden`}>
            {recipe.imageUrl ? (
              <img
                alt='pic'
                width='240'
                height='160'
                className='absolute min-h-full min-w-full object-cover'
                src={recipe.imageUrl}
              />
            ) : (
              <div
                className='h-full w-full'
                style={{ backgroundColor: recipe.accentColour ?? undefined }}
              ></div>
            )}
          </figure>
          <div className='card-body'>
            <h2 className='card-title'>{recipe.name}</h2>
            <p>{recipe.notes}</p>
          </div>

          <div className={'p-4/ flex w-full flex-row justify-between p-2'}>
            {openShareModal && <button
              onClick={(e) => {
                e.preventDefault();
                openShareModal();
              }}
              className={'btn btn-circle btn-ghost btn-sm'}
            >
              <MdShare />
            </button>}
            {/* <Dialog> */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className={'/p-1 btn ml-auto btn-circle btn-ghost btn-sm'}
                >
                  <RxDotsVertical />
                </button>
                {/* <Button variant='outline'>Open</Button> */}
              </DropdownMenuTrigger>
              <DropdownMenuContent className='dark:border-base-100 dark:bg-base-300'>
                <DropdownMenuItem asChild className='hover:bg-base-100/ dark:focus:bg-base-100'>
                  <Link to='/recipes/$slug/edit' params={{ slug: recipe.shortSlug }}>
                    <Edit className='mr-2 h-4 w-4 dark:text-neutral-300' />
                    <span className=''>Edit</span>
                  </Link>
                </DropdownMenuItem>

                {triggerDelete && <DropdownMenuItem
                  className='dark:focus:bg-base-100'
                  onClick={(e) => {
                    e.stopPropagation();
                    triggerDelete();
                  }}
                >
                  <Trash2 className='mr-2 h-4 w-4 text-red-500' />
                  <span className=''>Delete</span>

                  {/* <Button variant='outline'>Show Dialog</Button> */}

                  {/* <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut> */}
                </DropdownMenuItem>
                }
              </DropdownMenuContent>
            </DropdownMenu>
            {/* </Dialog> */}
            {/* <div className='dropdown dropdown-top '>
            <button
              tabIndex={0}
              onClick={(e) => {
                e.preventDefault();
              }}
              className={'/p-1 btn btn-circle btn-ghost btn-sm'}
            >
              <RxDotsVertical />
            </button>
            <div
              tabIndex={0}
              className='relative/ z-[1]/ menu card dropdown-content z-50 flex flex-col bg-base-100 '
            >
              <li className=''>
                <button>
                  <MdOutlineDeleteForever className='text-red-600' />
                  Delete
                </button>
              </li>
            </div>
          </div> */}
          </div>
        </div>
      </Link>
    </>
  );
}
