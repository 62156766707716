import { IoPencil } from 'react-icons/io5';

export function ItemsSection({
  items,
  title,
  selectedItem,
  setSelectedItem,
  status,
  edit
}: {
  items: { id: string; name: string }[];
  title: string;
  selectedItem: string | null;
  setSelectedItem: (id: string) => void;
  status?: 'success' | 'pending' | 'error' | 'idle'; // idle = parent not selected;
  edit: (item: string) => void;
}) {
  const isLoading = status === 'pending';
  const isError = status === 'error';
  const noContent = status === 'success' && items.length === 0;
  const isIdle = status === 'idle';

  return (
    <section className='mx-4 max-h-full grow'>
      <ul className='h-/ menu menu-vertical max-h-full flex-nowrap overflow-auto rounded-box bg-base-200 p-2'>
        <li className='menu-title pl-2 dark:text-neutral-50'>{title}</li>
        {!isIdle && (
          <li
            className={`ml-2/ btn btn-ghost items-start p-2 text-neutral-600 transition-all ease-in-out hover:font-bold hover:text-primary dark:text-neutral-400`}
          >
            + Create new?
          </li>
        )}
        <li className='pl-2'>
          {isLoading && 'Loading...'}
          {isError && <span className='text-red-500'>Error</span>}
          {/*{noContent && 'None exist'}*/}
          {isIdle && 'Select a' + (title === 'Ingredients' ? ' category' : 'n ingredient')}
        </li>
        {items && items.length > 0
          ? items?.map((item) => (
              <li
                onClick={() => {
                  console.log('li click');
                  setSelectedItem(item.id);
                }}
                className={`${selectedItem === item.id ? 'active ' : '/bg-base-200'} p-2/ btn/ btn-ghost/ group mx-2 items-start `}
                key={item.id}
              >
                <span
                  className={
                    'btn btn-ghost h-full w-full items-center justify-between text-left group-[.active]:bg-primary group-[.active]:text-neutral-950 group-[.active]:hover:bg-primary/50 group-[.active]:dark:hover:bg-primary/70'
                  }
                >
                  <span>{item.name}</span>
                  <button
                    onClick={(e) => {
                      console.log('pencil click');
                      e.stopPropagation();
                      edit(item.id);
                    }}
                    className={
                      'btn btn-square btn-ghost btn-sm my-2 ml-auto text-transparent group-hover:text-neutral-400 group-active:block group-[.active]:group-hover:text-neutral-800'
                    }
                  >
                    <IoPencil className={' text-lg'} />
                  </button>
                </span>
              </li>
            ))
          : ''}
      </ul>
    </section>
  );
}
