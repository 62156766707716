import { createFileRoute, Link, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_app/recipes/_list')({
  component: () => (
    <div className='flex w-full flex-col items-center'>
      <div role='tablist' className='tabs-boxed tabs mt-2'>
        <Link
          to='/recipes'
          role='tab'
          className={`tab`}
          activeOptions={{ exact: true, includeSearch: false }}
          activeProps={{ className: 'tab-active' }}
        >
          <span className='text-base'>My Recipes</span>
        </Link>
        <Link
          to='/recipes/explore'
          role='tab'
          activeProps={{ className: 'tab-active' }}
          className={`tab`}
        >
          <span className='text-base'>Explore</span>
        </Link>
        {/* todo recently explored? shared with me? */}
      </div>
      {/* Hello /_app/recipes/_list! */}
      <Outlet />
    </div>
  )
});
